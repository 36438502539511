import Image_Prefix from "../../assets";
export default function data(role) {
  switch (role) {
    case "admin":
      return [
        {
          label: "Dashboard",
          svgwhite: Image_Prefix.dashboard_icon,
          svgblue: Image_Prefix.dashboard_icon_blue,
          link: "/admin/dashboard",
        },
        {
          label: "Education",
          svgwhite: Image_Prefix.education_white,
          svgblue: Image_Prefix.education_blue,
          link: "#",
          links: [
            {
              label: "Exams",
              link: "#",
              links: [
                { label: "Series", link: "/ts" },
                { label: "Tests", link: "/tests" },
              ],
            },
            {
              label: "Question Bank",
              link: "#",
              links: [
                { label: "View All", link: "/home/qb/list" },
                { label: "Add New", link: "/home/qb/add" },
              ],
            },
            {
              label: "Stories",
              link: "/stories",
            },
            {
              label: "Static Material",
              link: "/sm/categories",
            },
            {
              label: "Static Content",
              link: "/sc/list",
            },
          ],
        },
        {
          label: "Billings",
          svgwhite: Image_Prefix.billing_white,
          svgblue: Image_Prefix.billing_blue,
          link: "#",
          initiallyOpened: false,
          links: [
            { label: "Orders", link: "/orders" },
            {
              label: "Payments",
              link: "#",
              links: [{ label: "Claim Requests", link: "/pay/claim/req" }],
            },
          ],
        },
        {
          label: "Admin",
          link: "#",
          svgwhite: Image_Prefix.key_white,
          svgblue: Image_Prefix.key_icon,
          links: [
            {
              label: "Accounts",
              link: "#",
              links: [
                { label: "Users", link: "/users" },
                { label: "Staff", link: "/staff" },
                { label: "Clients", link: "/client" },
                { label: "Content Managers", link: "/cm" },
              ],
            },
            { label: "Settings", link: "/manage" },
            {
              label: "Security",
              link: "#",
              links: [
                { label: "Enable 2FA", link: "/" },
                { label: "Change password", link: "/" },
                { label: "Recovery codes", link: "/" },
              ],
            },
          ],
        },
        {
          label: "Configuration",
          link: "#",
          svgwhite: Image_Prefix.configuration_white,
          svgblue: Image_Prefix.configuration_blue,
          links: [
            {
              label: "Systems",
              link: "/config/systems",
            },
            {
              label: "Test",
              link: "/config/tests",
            },
          ],
        },
        {
          label: "Notifications",
          link: "#",
          svgwhite: Image_Prefix.sidebar_notification_white,
          svgblue: Image_Prefix.sidebar_notification_blue,
          links: [
            {
              label: "Exams",
              link: "/notifications/exams/list",
            },
          ],
        },
      ];
    case "content_validator":
    case "content_feeder":
      return [
        {
          label: "Dashboard",
          link: "/cm/dashboard",
          svgblue: Image_Prefix.dashboard_icon_blue,
          svgwhite: Image_Prefix.dashboard_icon,
        },
        {
          label: "Tasks",
          link: "/cm/tasks",
          svgblue: Image_Prefix.tasks_icon,
          svgwhite: Image_Prefix.tasks_white,
        },
        {
          label: "Help & FAQ",
          link: "/cm/FAQ's",
          svgblue: Image_Prefix.help_faq_icon,
          svgwhite: Image_Prefix.help_faq_white,
        },
        {
          label: "Claim History",
          link: "/cm/claimHistory",
          svgblue: Image_Prefix.rupees_icon,
          svgwhite: Image_Prefix.rupees_icon_white,
        },
      ];
  }
}
